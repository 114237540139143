$lightGreen: #cde3b1;
$mediumLightGreen: #76A058;
$green: #508046;
$darkGreen: #264425;
$white: #ffffff;
$orange: orange;
$lightblue: skyblue;
$red: #b22222;
$lightgray: #bbb;
$tan: #cecdbd;
$lightTan: #dddcd5;
$textDark: #333;

@mixin xs {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin sm {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin md {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin lg {
    @media (min-width: 1200px) {
        @content;
    }
}


.small-text {
    font-size: 0.9em;
}

.flex-row-btwn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
}

.width100 {
    width: 100%;
}

.edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25em;
    margin-left: 0.5em;
    border-radius: 4px;
}

.edit-button:hover {
    background-color: $orange;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transition: 500ms;
}

.button {
    background-color: $lightblue;
    padding: 2px 1em;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transition: 700ms;
    text-align: center;
}

.button:hover {
    cursor: pointer;
    background-color: rgb(183, 230, 249);
    transition: 700ms;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.highlight-button {
    border: $lightGreen 0.5px solid;
    border-radius: 4px;
    background-color: $darkGreen;
}

.top-margin {
    margin-top: 1em;
}

.close:hover {
    color: $red;
    cursor: pointer;
    align-items: flex-start;
}

.navbar-container {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 8vh;
    margin-bottom: .6em;
}

.space-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.red-text {
    color: rgb(246, 93, 93);
}

.hidden {
    display: none;
}

.margin-left {
    margin-left: 0.5em;
}

.margin-right {
    margin-right: .5em;
}