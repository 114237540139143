@import '../../../../global.scss';

.search-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.search-results-utility-count {
    margin-top: 1em;
}

.drop-down-items-container {
    display: flex;
    flex-direction: column;
    // z-index: 1;
    position: absolute;
    width:fit-content;
    background-color: $white;
    color: black;
    border-radius: 0.25em;
    padding: 0.5em 0;
}

.drop-down-name {
    padding: 0.25em 0.25em 0.25em 0.4em;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.drop-down-name:hover {
    cursor: pointer;
    background-color: $green;
}

.drop-down-checkbox {
    margin-right: 4px;
}


.drop-down-item input:checked + .checkmark {
    background-color: $lightblue;
}

.drop-down-item:hover {
    background-color: $green;
    cursor: pointer;
}

.sort-item:hover {
    background-color: $lightblue;
    cursor: pointer;
}


.drop-down-item, .sort-item {
    padding: 0.25em 0.5em;
    margin: 0.1em 1;
    display: flex;
    align-items: center;
    border-radius: 3px;
}

.cost-input-container {
    padding: 0.25em 0.5em;
    display: flex;
    flex-direction: column;
    min-width: 176.7px;
}

.cost-input {
    margin: 0.5em 0.2em;
    padding: 0.1em 0.5em;
    width: 80px;
    border: none;
}

.cost-filter-container {
    width: 200px;
}


// .sort-adjust {
    // left: 70px;
// }

.search-input-container {
    display: flex;
    flex-direction: row;
    background-color:$white;
    border-radius: 4px;
    height: 1.7em;
    width: 30vw;
    min-width: 450px;
}

.search-input-container:focus-within, .cost-input:focus {
    border-radius: 4px;
    box-shadow: rgba(205, 227, 177, 0.7) 0px 0px 0px 3px;
    outline: none;
}

.search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $darkGreen;
    padding: 5px;
}

.search-input:focus {
    outline: none;
}

.search-input {
    border: none;
    border-radius: 4px;
    width: 100%;
}

input::placeholder {
    color: $green;
}

.filter-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: $white;
    background-color: $darkGreen;
    padding: 1em;
    border-radius: 4px;
    margin-top: 0.5em;
    overflow-x: auto;
    min-height: 223px;
    box-sizing: border-box;
}

.programs-search-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 286.11px;
}

.drop-down-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}
.filters-button {
    min-width: 120px;
    width: auto;
}

.filters-text {
    display: flex;
    align-items: center;
}

.filter-icon {
    padding: 0 5px;
    display: flex;
    align-items: center;
}

.search-button {
    margin-left: 1em;
    width: 80px;
}

.search-button div {
    display: flex;
    justify-content: center;
}


.drop-down-button {
    background-color: $darkGreen;
    border-radius: 4px;
    padding:0.5px;
}

.gppfeatures {
    min-width: 254.37px;
}

.certifications {
    min-width: 151.53px;
}

.contractlengths {
    min-width: 156.3px;
}

.left-col, .middle-col, .right-col {
    width: 33%;
    display: flex;
}

.middle-col {
    justify-content: center;
}

.right-col {
    justify-content: end;
}