@import '../../global.scss';

.utility-item-container {
    display:flex;
    flex-direction: column;
    text-decoration: none;
    margin-top: 2em;
    background-color: $lightTan;
    color: black;
    border-radius: 1em;
}

.utility-item-container:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: 300ms;
}

.utility-item-header {
    background-color: $green;
    color: $white;
    text-align: center;
    padding: 0.35em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    font-size: 1.5em;
    font-weight: 400;
}

.utility-item-summary-lz {
    background-color: $white;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding: 0.5em;
    width: 100%;
}

.utility-item-summary-container {
    // overflow-y: scroll;
    padding: 1em 0 0 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: $darkGreen;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    flex-wrap: wrap;
}

.lz-container {
    background-color: $lightTan;
    padding: 2em;
    border-radius: 1em;
    margin-top: 1em;
}

.utility-index-results-container {
    margin: 2vh 5vw;
    background-color: $lightTan;
    border-radius: 2em;
    padding: 0.01em 2em 2em;
    position: relative;
    // top: 20vh;
}

.utility-index-header {
    // font-family: Leafy, sans-serif;
    font-weight: 900;
    // font-size: 1.5em;
    text-align: center;
    // padding: 0.5em;
    color: $lightGreen;
    text-decoration: none;
}

.utility-index-item-region-span {
    padding-left: 0.25em;
    // font-size: 1.2em;
}

.utility-item-lz-header {
    background-color: $tan;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding: 0.5em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.utility-item-state {
    font-weight: 500;
    font-size: 1.2em;
}

.utility-item {
    min-width: 300px;
    max-width: 500px;
    flex-basis: auto;
    flex-grow: 1;
    margin-bottom: 1em;
    margin-right: 2em;
}

.shaded-text{
    color: rgb(100, 93, 93);
}

.utility-show-contianer {
    height: 100vh;
    position: fixed;
    // display: block;
}

.utility-show-header {
    color: $white;
    text-align: center;
    font-size: 2em;
}

.navlink {
    text-decoration: None;
    display: flex;
    align-items: center;
}


.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.lz-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.lz-header-right {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.lz-header-aliases {
    padding: 0 0.5em;
}

.retail-utility-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.retail-utility-header-column {
    margin-bottom: 0.5em;
}

.right-justified {
    text-align: end;
}

.green-text {
    color: $green
}

.white-text {
    color: $white;
}

.utility-index-header-container {
    border-bottom: 0.5px $lightgray solid;
    padding: 2vh 5vw;
    position: fixed;
    width: 100%;
    z-index: 1;
    top: -4px;
    opacity: 1;
    background-color: rgb(13, 42, 5);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // justify-content: space-between;
}

.utility-index-gpp {
    display: flex;
    align-items: center;
    // padding-left: 1em;
}

.utility-show-loadzones {
    position: relative;
    top: 145px;
    display: block;
    margin: 0 5vw;
    padding-bottom: 2em;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.lds-roller {
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pagination-container {
    padding-top: 2em;
    display: flex;
    justify-content: center;
}

.gpp-name {
    font-size: larger;
}