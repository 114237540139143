@import '../../../global.scss';

.retail-utility-container {
    background-color: $white;
    padding: 1em;
    margin: 1em 0;
    border-radius: 0.5em;
}

.retail-utility-container h2 {
    font-size: 1.2em;
    text-align: center;
}

.table-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.table-header {
    background-color: $mediumLightGreen;
    padding: 0.5em;
    text-align: center;
}

.rounded-top {
    margin-top: 1em;
    border-top-left-radius: 0.75em;
    border-top-right-radius: 0.75em;
}

.table-item, .table-header-item, .table-row-item, .table-col-header {
    padding: 0.5em;
    background-color: $white;
    border: 0.5px solid $mediumLightGreen;
    text-align: center;
    // color: $darkGreen;
    font-size: 0.9em;
    width: 100%;
}

#narrow {
    width: 18%;
}

#wide {
    width: 46%;
}

.full {
    width: 100%;
}

#phone {
    width: 16%;
}

#email {
    width: 34%;
}

#url {
    width: 50%;
}

.table-container:last-child .table-item:first-child,
.bottom-left-rad 
{
    border-bottom-left-radius: 0.5em;
}
.table-container:last-child .table-item:last-child,
.bottom-right-rad
{
    border-bottom-right-radius: 0.5em;
}

.green-shaded {
    background-color: $lightGreen;
}

.program-container {
    background-color: $white;
    padding: 1em;
    border-radius: 0.5em;
    margin-bottom: 1em;
}

.program-container:last-child {
    margin-bottom: 0;
}

.gpp-container {
    border-bottom-left-radius: 0.75em;
    border-bottom-right-radius: 0.75em;
    background-color: $darkGreen;
    padding: 1em;
}

.edit-retail-container {
    display: flex;
    flex-direction: column;
}

.edit-retail-container label {
    margin-top: 1em;
    font-weight: 500;
}

.edit-link-input {
    width: 100%;
    flex-wrap: wrap;
}

.edit-textarea {
    width: 100%;
    flex-wrap: wrap;
    resize: none; 
    margin-bottom: 0.5em;
}


.table-row {
    display: flex;
    flex-direction: row;
}

.table-row-item {
    width: 100%;
    text-align: left;
}



.table-col-header {
    width: 10em;
}